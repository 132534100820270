<template>
    <div class="orders">
        <div class="orders__container">
            <div class="orders__orders-list">
                <template v-for="order in orders">
                    <OrderCard
                        :key="`order-${order.id}`"
                        :order="order"
                        @click="details(order)"
                    />
                </template>
            </div>

            <PopupOrderDetail
                v-model="showDetails"
                :order="activeOrder"
                @close="closeDetails"
                @update="fetch"
            />
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';

    import OrderCard from '../components/order/OrderCard';
    import PopupOrderDetail from '../components/order/PopupOrderDetail';

    export default {
        name: 'Orders',
        metaInfo() {
            return {
                title: 'Заказы'
            }
        },
        components: {
            OrderCard,
            PopupOrderDetail,
        },
        data: () => ({
            showDetails: false,
            activeOrder: {}
        }),
        computed: {
            ...mapState('users', {
                user: state => state.user
            }),
            ...mapState('orders', {
                orders: state => state.entities,
            }),
        },
        async mounted() {
            store.commit('entities/CLEAR_ENTITY');
            if(!this.user.id) {
                await store.dispatch('users/fetch');
            }
            if(this.user.id) {
                await store.dispatch('orders/statuses');
                await this.fetch();
            } else {
                this.$router.push('/');
            }
            if(this.$route.params.external_id) {
                this.details(this.orders.find(item => item.external_id === this.$route.params.external_id));
            }
        },
        methods: {
            async fetch() {
                await store.dispatch('orders/fetch', {});
            },
            details(order) {
                this.activeOrder = order;
                this.showDetails = true;
            },
            closeDetails() {
                this.showDetails = false;
            }
        }
    }
</script>

<style lang="scss">
    .orders {
        width: 100%;
        max-width: 1200px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin: 0 auto;

        &__container {
            width: 100%;
        }

        &__orders-list {
            padding: 20px 0;
            display: grid;
            grid-gap: 20px;
            grid-template-columns: repeat(3, 1fr);
            
            @media screen and (max-width: 1200px) {
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 10px;
            }
            
            @media screen and (max-width: 600px) {
                grid-template-columns: repeat(1, 1fr);
                grid-gap: 10px;
            }
        }

        &__cart {
            width: 350px;
            height: calc(100vh - 90px);
            padding: 0 0 0 30px;
            position: sticky;
            top: 90px;

            @media screen and (max-width: 1400px) {
                display: none;
            }
        }
    }
</style>
